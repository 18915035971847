<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_user') || isAdmin()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  v-if="forConasi"
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.userName"
                    :label="$t('users.username')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.userFullName"
                    :label="$t('users.fullName')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.userPhone"
                    :label="$t('users.phone')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.userEmail"
                    :label="$t('users.email')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('users.userList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="hasAction('create_user') && !isAdmin() && !isEntityDisabled() || isAdmin()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddUserAvailable()"
            >
              {{ $t('users.addUserAvailable') }}
            </v-btn>
            <v-btn
              v-if="hasAction('create_user') && !isEntityDisabled() || isAdmin()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddUser(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <div
            v-if="hasAction('view_user') || isAdmin()"
            @mouseleave="$refs.tblUserList.expanded=[]">
            <v-data-table
              ref="tblUserList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="userHeaders"
              :items="userList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  @click="props.expanded=!props.expanded"
                  @mouseover="props.expanded=true">
                  <td>{{ props.item.stt }}</td>
                  <td>
                    <div style="display: inline-block;">
                      <avatar :username="props.item.fullname"/>
                    </div>
                  </td>
                  <td v-if="forConasi">{{ props.item.identification }}</td>
                  <td>{{ props.item.email }}</td>
                  <td>{{ props.item.fullname }}</td>
                  <td>{{ props.item.phone }}</td>
                  <td v-if="isAdmin()">
                    <span :class="['a-state', (props.item.systemRole == SysRoleType.ADMIN) ? 'warning' : 'default']">
                      {{ $t(getSysRoleName(props.item.systemRole)) }}
                    </span>
                  </td>
                  <td style="white-space: nowrap;">
                    <div v-if="props.item.numberOfEntity>0">
                      <div
                        v-for="(entityRole, index) in props.item.entityRoles"
                        :key="entityRole + index"
                        class="py-1">
                        <span
                          v-if="isAdmin()"
                          class="a-state default"><b>{{ entityRole.entity_name }}</b> - {{ $t(getRoleInEntityName(entityRole.role_type)) }}</span>
                      </div>
                    </div>
                  </td>
                  <!-- <td class="text-xs-center"><span v-if="props.item.numberOfEntity>0">{{ props.item.numberOfEntity }}</span></td> -->
                  <td class="text-xs-center">
                    <v-tooltip
                      v-if="!props.item.isActive"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="error"
                          dark
                          v-on="on">mdi-close-outline</v-icon>
                      </template>
                      <span>{{ $t('evoucher.state.inactive') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else
                      top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="success"
                          dark
                          v-on="on">mdi-check-outline</v-icon>
                      </template>
                      <span>{{ $t('evoucher.state.active') }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-xs-center">
                    <v-tooltip
                      v-if="isAdmin()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :loading="props.item.isLoading"
                          class="mx-2"
                          fab
                          small
                          color="success"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalResetPassword(props.item)">
                          <v-icon dark>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.resetPassword') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  class="py-2 mx-3"
                  style="display: flex; white-space: nowrap; padding-left: 35px"
                  @mouseleave="props.expanded=false">
                  <action-item
                    first
                    class="text-success"
                    @click="onShowModalViewDetailUser(props.item)">
                    {{ $t('evoucher.viewDetail') }}
                  </action-item>
                  <action-item
                    v-if="(hasAction('manage_user') && props.item.id != GET_CURRENT_ACCOUNT.id && !isEntityDisabled()) || isAdmin()"
                    style="color: red;"
                    @click="onShowModalRemoveOrDeleteUser(props.item.id)">
                    {{ $t('common.delete') }}
                  </action-item>
                  <action-item
                    v-if="!props.item.isActive && isAdmin()"
                    @click="onActiveUser(props.item.id)">
                    {{ $t('user.is_active') }}
                  </action-item>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="userPaginate.totalPage"
      :current-page="userPaginate.currentPage"
      :row-per-page="userPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addUserModal ref="modalAddUser"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="modalConfirmTypeSelected == modalConfirmType.DELETE_USER ? onRemoveOrDeleteUser() : onResetPassword()"
    />
    <userDetailModal ref="userDetailModal" />
    <userEntityManagementModal
      ref="userEntityManagementModal"
      @addUserInEntitySuccess="onAddUserInEntitySuccess" />
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import AddUserModal from 'Components/AddUserModal'
import Avatar from 'vue-avatar'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import UserDetailModal from 'Components/UserDetailModal'
import LoginFromType from 'enum/loginFromType'
import SysRoleType from 'enum/sysRoleType'
import UserEntityManagementModal from 'Views/Agent/UserInEntityManagementModal'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ActionItem from 'Components/ActionItem.vue'
import EntityRoleType from 'enum/entityRoleType'
export default {
  components: {
    addUserModal: AddUserModal,
    Avatar,
    ConfirmModal,
    UserDetailModal,
    UserEntityManagementModal,
    ActionItem
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'common.logo',
        value: 'avatar'
      },
      {
        sortable: false,
        text: 'users.identification',
        value: 'identification'
      },
      {
        sortable: false,
        text: 'user.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'users.fullName',
        value: 'fullname'
      },
      {
        sortable: false,
        text: 'users.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'users.adminPrivileges',
        value: 'systemRole'
      },
      // {
      //   sortable: false,
      //   text: 'users.userPrivileges',
      //   value: 'entityRoles'
      // },
      {
        sortable: false,
        text: 'entity.inEntities',
        value: 'numberOfEntity',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'isActive',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    userList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    userSelected: {
      id: null
    },
    isUpdate: true,
    dobDefault: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    selected: [],
    userSearch: {
      userName: null,
      userFullName: null,
      userPhone: null,
      userGender: null,
      userEmail: null
    },
    gender: stringUtils.GenderList,
    genderList: null,
    isLoading: false,
    isActive: false,
    modalConfirmType: {
      DELETE_USER: 1,
      FORGOT_PASSWORD_USER: 2
    },
    SysRoleType: SysRoleType,
    modalConfirmTypeSelected: null,
    userForgotPassSelected: null,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'USER_LIST_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    USER_LIST_DATA () {
      let res = this.USER_LIST_DATA
      this.userPaginate.currentPage = res.paginate.currentPage
      this.userPaginate.totalPage = res.paginate.totalPage
      this.userPaginate.rowPerPage = res.paginate.perPage
      let userList = res.results
      this.userList = []
      for (let i = 0, size = userList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          avatar: userList[i].profile && userList[i].profile.url_avatar,
          id: userList[i].id,
          username: userList[i].username,
          fullname: functionUtils.concatFullname(userList[i].last_name, userList[i].first_name),
          phone: userList[i].profile && userList[i].profile.phone,
          gender: userList[i].profile && userList[i].profile.gender,
          dob: this.getDateOfBirth(userList[i].profile.dob),
          email: userList[i].email,
          isActive: userList[i].profile && userList[i].profile.is_active,
          dateCreated: this.getDateOfBirth(userList[i].date_created),
          systemRole: userList[i].sys_role,
          entityRoles: userList[i].entity_roles,
          numberOfEntity: userList[i].total_entity_have_user,
          isLoading: false,
          identification: userList[i].extra_info.identification
        }
        this.userList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {
    if (!FOR_CONASI_TARGET) {
      this.userHeaders = this.userHeaders.filter(element => {
        return element.value !== 'identification'
      })
    }
    if (!this.isAdmin()) {
      this.userHeaders = this.userHeaders.filter(element => {
        return element.value !== 'systemRole' && element.value !== 'numberOfEntity' && element.value !== 'actions'
      })
    }
    this.getUserList()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Add user in entity success
     */
    onAddUserInEntitySuccess: function () {
      this.getUserList()
    },
    /**
     * Show modal add user
     */
    onShowModalAddUserAvailable: function () {
      let entityId = sessionStorage.getItem('entityId')
      let entityType = sessionStorage.getItem('entityType')
      this.$refs.userEntityManagementModal.onShowModal(entityId, entityType)
    },
    /**
     * Show modal reset password
     */
    onShowModalResetPassword: function (user) {
      this.userForgotPassSelected = user
      this.confirmModalTitle = this.$t('forgotPassword.confirmResetPassword')
      this.modalConfirmTypeSelected = this.modalConfirmType.FORGOT_PASSWORD_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Reset password
     */
    onResetPassword: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        email: this.userForgotPassSelected.email,
        fromDomainType: this.userForgotPassSelected.numberOfEntity !== 0 ? LoginFromType.ADMIN : LoginFromType.CUSTOMER,
        domainWithBasePath: this.userForgotPassSelected.numberOfEntity !== 0 ? stringUtils.DOMAIN_BASE_PATH_ADMIN : stringUtils.CUSTOMER_URL
      }
      this.userForgotPassSelected.isLoading = true
      this.SEND_EMAIL_FORGOT_PASSWORD(filter).then(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('forgotPassword.pleaseCheckEmailToChangePassword'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    getSysRoleName: function (sysRole) {
      return functionUtils.sysRoleName(sysRole)
    },
    getRoleInEntityName: function (role) {
      return functionUtils.roleInEntityName(role)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isManager: function () {
      return functionUtils.roleInEntity(EntityRoleType.MANAGER)
    },
    /**
     * Active user
     */
    onActiveUser: function (userId) {
      if (!this.isAdmin()) {
        return // Oly admi can perform this action
      }
      this.ACTIVE_USER({ id: userId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.isLoading = true
          this.getUserList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Show modal view detail user
     */
    onShowModalViewDetailUser: function (item) {
      this.$refs.userDetailModal.onShowModal(item)
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getUserList()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Show modal add user
     */
    onShowModalAddUser: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.modalAddUser.onShowModal(this.userPaginate.currentPage)
    },
    /**
     * Show modal delete user
     */
    onShowModalRemoveOrDeleteUser: function (userId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.userSelected.id = userId
      if (this.isAdmin()) {
        this.confirmModalTitle = this.$t('user.confirmDeleteUserTitle')
      } else {
        this.confirmModalTitle = this.$t('user.confirmRemoveUserTitle')
      }
      this.modalConfirmTypeSelected = this.modalConfirmType.DELETE_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete user
     */
    onRemoveOrDeleteUser: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.DELETE_USER({ id: this.userSelected.id }).then(
        function () {
          this.userSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getUserList()
        }.bind(this)
      )
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      return dateUtils.formatBeautyDate(dob, null)
    },
    /**
     * Get gender
     */
    getGender: function (gender) {
      return functionUtils.getGender(gender)
    },
    /**
     * Get user list
     */
    getUserList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          userName: this.userSearch.userName != null ? this.userSearch.userName.trim() : null,
          userFullName: this.userSearch.userFullName != null ? this.userSearch.userFullName.trim() : null,
          userPhone: this.userSearch.userPhone != null ? this.userSearch.userPhone.trim() : null,
          userEmail: this.userSearch.userEmail != null ? this.userSearch.userEmail.trim() : null
        }
      }
      this.GET_USER_LIST(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getUserList()
    },
    ...mapActions([
      'GET_USER_LIST',
      'UPDATE_USER_PROFILE',
      'DELETE_USER',
      'ON_SHOW_TOAST',
      'ACTIVE_USER',
      'SEND_EMAIL_FORGOT_PASSWORD'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
